// React Types
import {FC, ReactElement} from "react";
// Styles
import "./information.scss";
// Components
import MailTo from "../MailTo/MailTo"
import {useForm} from "react-hook-form"
import {yupResolver} from "@hookform/resolvers/yup"
import * as yup from "yup"

const schema = yup
    .object({
        name: yup.string().required('Required'),
        message: yup.string().required('Required'),
        email: yup.string().email().required('Required'),
    })
    .required()


const Information: FC<{ information: information }> = ({
                                                           information,
                                                       }): ReactElement => {
    // const [show, setShow] = useState(false);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schema),
    })
    const onSubmit = (data: any) => {
        const {email, message, name} = data;
        const mailtoLink = `mailto:${`info@tigersaysavanh.com`}?subject=${encodeURIComponent(`I need your services`)}&body=${encodeURIComponent(`name: ${name} \n\n ${message}`)}`;
        window.location.href = mailtoLink;
        console.log(data)
    }

    return (
        <div
            className="information-container"
        >
            <div className={`info-container`}>
                <div>

                    <img
                        className="information-image"
                        src={information.cover}
                        alt="information"
                    />
                    {/*<div>*/}
                    {/*    <p>{information.information}</p>*/}
                    {/*    <p style={{marginBottom: "30px"}}>CV available upon request.</p>*/}
                    {/*    <MailTo label="Contact Me: info@tigersaysavanh.com" mailto="mailto:info@tigersaysavanh.com"/>*/}
                    {/*</div>*/}
                </div>
                <div>
                    <form onSubmit={handleSubmit(onSubmit)} className={'form-container'}>
                        <div className={`inputs-container`}>
                            <div className={'input-container'}>
                                {/*<label htmlFor="">Name</label>*/}
                                <input
                                    placeholder={'Name'}
                                    {...register("name")}
                                    className={`input input-name`}
                                    type="text"
                                />
                                {errors.name && (
                                    <p className={'error'}>{errors.name?.message}</p>
                                )}
                            </div>
                            <div className={'input-container'}>
                                {/*<label htmlFor="">Email</label>*/}
                                <input
                                    placeholder={'Email'}
                                    {...register("email")}
                                    className={'input input-email'} type="email"
                                />
                                {errors.email && (
                                    <p className={'error'}>{errors.email?.message}</p>
                                )}

                            </div>
                            <div className={'input-container'}>
                                {/*<label htmlFor="">Message</label>*/}
                                <textarea
                                    placeholder={'Message'}
                                    {...register('message')}
                                    className={`input input-message`}
                                    rows={10}
                                />
                                {errors.message && (
                                    <p className={'error'}>{errors.message?.message}</p>
                                )}
                            </div>
                        </div>
                        <div className={`btn-container `}>
                            <button className={`btn`}>
                                Send Message
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/*<main className="tiger-container">*/}
            {/*    <p>{information.information}</p>*/}
            {/*    <p style={{marginBottom: "30px"}}>CV available upon request.</p>*/}
            {/*    <MailTo label="Contact Me: info@tigersaysavanh.com" mailto="mailto:info@tigersaysavanh.com"/>*/}
            {/* <button className="button-container" onClick={() => setShow(true)}>
            CONTACT
          </button> */}
            {/*</main>*/}
            {/*<section className="clients-container">*/}
            {/*  <p className="clients-title">CLIENTS:</p>*/}
            {/*  <hr />*/}
            {/*  <div className="clients-grid">*/}
            {/*    {information.clients.map((client) => (*/}
            {/*      <div key={client.key} className="client-container">*/}
            {/*        <a href={client.url} className="client-url">*/}
            {/*          {client.title}*/}
            {/*        </a>*/}
            {/*      </div>*/}
            {/*    ))}*/}
            {/*  </div>*/}
            {/*</section>*/}
        </div>
    );
};

export default Information;
