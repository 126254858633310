// @ts-nocheck

// React Types
import {FC, ReactElement, useContext} from "react";
//@ts-ignore
import DelayLink from "react-delay-link";
// Styles
import "./landscapeGrid.scss";
//@ts-ignore
import {Context} from "../../contexts/Context";
import Slider from "react-slick";

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={`${className} arrow`}
            style={{...style, display: "block"}}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div
            className={`${className} arrow`}
            style={{...style, display: "block",}}
            onClick={onClick}
        />
    );
}

const LandscapeGrid: FC<{ media: Array<category> | Array<video>, isVideo?: boolean }> = ({
                                                                          media,
    isVideo
                                                                      }): ReactElement => {
    //@ts-ignore
    const dispatch = useContext(Context)[1];
    console.log(media)

    const settings = {
        dots: true,
        arrows: true,
        autoplay: false,
        swipeToSlide: true,
        centerPadding: "60px",
        infinite: true,
        speed: 500,
        // slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        variableWidth: true,
    };

    return (
        <main className={'landscape-main'} >

                <Slider {...settings}>

                    {media &&
                        media.map((element: category | video) => {
                            return (
                                <DelayLink
                                    delay={750}
                                    to={isVideo ? element.slug : '#'}
                                    // to={'#'}
                                    key={element.key}
                                    clickAction={() => {
                                        if (isVideo) {
                                        dispatch({type: "start_transition"})
                                        }
                                    }}
                                    replace={false}
                                >
                                    <div className={'landscape-container'}>
                                        {/*<div className="landscape-overlay"*/}
                                        {/*     style={{backgroundColor: `rgb(${element.color})`}}>*/}
                                        {/*    <p>{element.artist ? element.artist + " - " + element.title : element.title}</p>*/}
                                        {/*</div>*/}
                                        <img className="landscape-image" src={element.cover} alt=""/>
                                    </div>
                                </DelayLink>
                            );
                        })}
                </Slider>

        </main>
    );
};

export default LandscapeGrid;
